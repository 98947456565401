import errorHandleSimple from '@/helpers/errors/errorHandleSimple'
import axios from 'axios'
import { get } from 'lodash'
import Vue from 'vue'
import store from '@/store'
import moment from '@/helpers/useMoment'

const DEV_HOST_NAME = process.env.VUE_APP_DEV_HOST_NAME
const currentHostname = location && location.hostname
const isDev =
  process.env.VUE_APP_IS_DEV === 'true' ||
  process.env.NODE_ENV === 'development' ||
  currentHostname === DEV_HOST_NAME
const baseURL = ''

const instance = axios.create({
  baseURL: '/api/',
  timeout: 5 * 60 * 1000,
  headers: {
    Timezone: moment.tz.guess(),
  },
})
const sourceRequest = {}

const onRejected = (error) => {
  // console.log(error.toJSON())
  const { config } = error
  if (axios.isCancel(error)) {
    return Promise.reject(error.message || 'Operation canceled')
  }
  if (config?.url && sourceRequest[config.url]) {
    delete sourceRequest[config.url]
  }

  const status = get(error, 'response.status')
  const message = errorHandleSimple(error)
  const errors = get(error, 'response.data.errors')

  Vue.prototype.$toaster &&
    Vue.prototype.$toaster({
      text: message,
      list: errors ? Object.values(errors) : [],
      type: 'ERROR',
    })
  switch (status) {
    case 401: {
      store.dispatch('user/onLogout').catch(() => {})
      break
    }
    case 403: {
      // Forbidden
      break
    }
    default:
      break
  }
  return Promise.reject(error)
}
instance.interceptors.request.use((config) => {
  const controller = new AbortController()
  const requestUrl = config.url
  if (sourceRequest[requestUrl]) {
    sourceRequest[requestUrl].abort()
    delete sourceRequest[requestUrl]
  }
  sourceRequest[requestUrl] = controller
  return {
    ...config,
    signal: controller.signal,
  }
}, onRejected)
instance.interceptors.response.use((response) => {
  const responseUrl = response?.config?.url

  if (responseUrl && sourceRequest[responseUrl]) {
    delete sourceRequest[responseUrl]
  }
  if (
    process.env.NODE_ENV === 'development' &&
    response.headers['content-type'] === 'application/json'
  ) {
    const dataStr = JSON.stringify(response.data).replace(
      new RegExp(process.env.VUE_APP_DEV_BASE_URL, 'g'),
      location.origin
    )

    return {
      ...response,
      data: JSON.parse(dataStr),
    }
  }
  return response
}, onRejected)

if (process.env.NODE_ENV === 'production') {
  instance.defaults.headers.common['token_header'] =
    process.env.VUE_APP_SENTRY_HEADER_TOKEN
}

const setToken = (token) => {
  instance.defaults.headers.common['Authorization'] = token
}
const axiosApi = instance

export { axiosApi, isDev, baseURL, setToken }
