<template>
  <div>
    <v-modal name="menu" title="Menu" class="_full-content">
      <template>
        <TheMobileMenu />
      </template>
    </v-modal>
    <v-modal name="scan-receipt" title="Scan receipt" class="_x-wide">
      <template v-slot:default="props">
        <ModalScanReceipt v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="quick-attach" title="Quick attach" class="_medium-lg">
      <template v-slot:title="{ title }">
        <div v-html="title" style="font-size: 1.8rem; line-height: 1.3em"></div>
      </template>
      <template v-slot:default="props">
        <ModalQuickAttach v-bind="props" />
      </template>
    </v-modal>

    <!-- BASIS -->
    <v-modal name="confirm">
      <template v-slot:title="{ title }">
        {{ title || 'Confirm?' }}
      </template>
      <template v-slot:default="{ text, onConfirm }">
        <TheConfirm :text="text" :onConfirm="onConfirm" />
      </template>
    </v-modal>

    <v-modal name="default">
      <template v-slot:title="{ title }">
        {{ title }}
      </template>
      <template v-slot:default="{ item }">
        <div v-html="item || 'Empty'"></div>
      </template>
    </v-modal>

    <!-- LOGS  -->
    <LogsModal />

    <!-- SETTINGS -->
    <SettingsModals />

    <!-- MESSAGES -->
    <MessageModals />

    <!-- EMAILS -->
    <VibersModals />

    <!-- PERSONS -->
    <PersonsModals />

    <!-- BOOKING -->
    <BookingModals />

    <!-- INVOICE -->
    <InvoiceActionsModal />

    <!-- EXPENSE -->
    <ExpenseActionsModal />

    <!-- BILL -->
    <BillActionsModal />

    <!-- ESTIMATE -->
    <EstimateActionsModal />

    <!-- PAYMENTS RECEIVED -->
    <PaymentsReceivedModals />

    <!-- DEPOSIT -->
    <DepositActionsModal />

    <!-- RATE -->
    <RatesModals />

    <!-- COMMODITY EDIT -->
    <CommodityEditModals />

    <!-- Shiping File -->
    <ShippingFileModals />

    <!-- SUBSCRIBE -->
    <SubscribeModals />

    <!-- OTHER -->

    <v-modal name="edit-event">
      <template v-slot:title="{ event }">
        <template v-if="!event">New google event</template>
        <template v-else-if="event.calendarId === 'system'"
          >Edit system event
        </template>
        <template v-else>Edit google event</template>
      </template>
      <template v-slot:default="props">
        <EventEdit v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="new-inventory" title="New Inventory">
      <template v-slot:default="{ callback }">
        <InventoryEdit :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="new-file-tabs-attach-document" title="Attach file" class="">
      <template v-slot:default="{ onFileAttach: callback, truckingList }">
        <NewFileTabsAttachDocument
          :callback="callback"
          :truckingList="truckingList"
        />
      </template>
    </v-modal>

    <v-modal name="new-aes-itn" title="Create AES/ITN" class="_x-wide">
      <template>
        <AESITNEdit />
      </template>
    </v-modal>

    <v-modal name="add-new-document-name" title="Add new document name">
      <template v-slot:default="{ callback }">
        <AddNewDocumentType :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="photo-edit" title="Edit photo">
      <template v-slot:default="{ callback, photo }">
        <PhotoEdit :callback="callback" :photo="photo" />
      </template>
    </v-modal>

    <!--<v-modal name="b-estimate" title="Estimate" class="_wide">-->
    <!--  <template v-slot:default="{ id }">-->
    <!--    <ViewEstimate :id="id" class="c-estimate"/>-->
    <!--  </template>-->
    <!--</v-modal>-->

    <!--<v-modal name="b-invoice" title="Invoice" class="_wide">-->
    <!--  <template v-slot:default="{ id }">-->
    <!--    <ViewInvoice :id="id" class="c-invoice"/>-->
    <!--  </template>-->
    <!--</v-modal>-->

    <!--<v-modal name="b-payment" title="Payment" class="_wide">-->
    <!--  <template v-slot:default="{ id }">-->
    <!--    <ViewPayment :id="id" class="c-payment"/>-->
    <!--  </template>-->
    <!--</v-modal>-->

    <!--<v-modal name="b-expense" title="Expense" class="_wide">-->
    <!--  <template v-slot:default="{ expense }">-->
    <!--    <ViewExpense :booking="expense" class="c-expense"/>-->
    <!--  </template>-->
    <!--</v-modal>-->

    <v-modal name="new-time-log" title="New time log">
      <template>
        <TimeLogEdit />
      </template>
    </v-modal>

    <v-modal name="new-department">
      <template v-slot:title="{ title }">
        {{ title || 'New department' }}
      </template>
      <template v-slot:default="{ id, callback }">
        <DepartmentEdit :id="id" :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="new-office">
      <template v-slot:title="{ title }">
        {{ title || 'New office' }}
      </template>
      <template v-slot:default="{ id, callback }">
        <OfficeEdit :id="id" :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="new-employee-title">
      <template v-slot:title="{ title }">
        {{ title || 'New employee title' }}
      </template>
      <template v-slot:default="{ id, callback }">
        <EmployeeTitleEdit :id="id" :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="map" class="_x-wide _full-content _small-title">
      <template v-slot:title="{ address }">{{ address || 'Map' }}</template>
      <template v-slot:default="{ address }">
        <Map :address="address" />
      </template>
    </v-modal>

    <v-modal name="add-calendar" title="Add google calendar">
      <template v-slot:default="props">
        <CalendarAdd v-bind="props" />
      </template>
    </v-modal>

    <!--    <v-modal name="edit-calendar" title="New calendar">-->
    <!--      <template v-slot:default="{ callback, id }">-->
    <!--        <CalendarEdit :callback="callback" :id="id" />-->
    <!--      </template>-->
    <!--    </v-modal>-->

    <v-modal name="new-company-profile" title="New company">
      <template v-slot:default="{ callback }">
        <CompanyProfileEdit :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="edit-company-profile" title="Edit company profile">
      <template v-slot:default="{ callback, id }">
        <CompanyProfileEdit :callback="callback" :id="id" />
      </template>
    </v-modal>

    <v-modal name="qr-scanner" title="QR Scanner" class="c-modal-qr-scanner">
      <template v-slot:default="{ callback }">
        <qrScanner :callback="callback" />
      </template>
    </v-modal>

    <v-modal name="commodity-consignee" title="Commodity consignee">
      <template v-slot:default="{ id }">
        <SettingPerson
          :id="id"
          name-modal="commodity-consignee"
          httpBaseLink="/settings/commodities/consignees/objects/"
        />
      </template>
    </v-modal>

    <v-modal name="commodity-notify-party" title="Commodity notify party">
      <template v-slot:default="{ id }">
        <SettingPerson
          :id="id"
          name-modal="commodity-notify-party"
          httpBaseLink="/settings/commodities/notify_parties/objects/"
        />
      </template>
    </v-modal>

    <v-modal name="commodity-shipper" title="Commodity shipper">
      <template v-slot:default="{ id }">
        <SettingPerson
          :id="id"
          name-modal="commodity-shipper"
          httpBaseLink="/settings/commodities/shippers/objects/"
        />
      </template>
    </v-modal>

    <v-modal
      name="customer-send-on-email"
      title="Customer profile send on email"
    >
      <template
        v-slot:default="{
          link,
          emails,
          attachments,
          attachmentsTitle,
          callback,
        }"
      >
        <SendOnEmail
          :link="link"
          :emails="emails"
          :attachments="attachments"
          attachments-label-key="file_original_client_name"
          :attachmentsTitle="attachmentsTitle"
          :callback="callback"
          :modalsToClose="['customer-send-on-email']"
        />
      </template>
    </v-modal>

    <v-modal name="customer-share-contact" title="Customer share contact">
      <template v-slot:default="{ link, subject, message, callback }">
        <SendOnEmail
          :link="link"
          :subject="subject"
          :message="message"
          :callback="callback"
          :modalsToClose="['customer-share-contact']"
        />
      </template>
    </v-modal>

    <v-modal name="vendor-send-on-email" title="Vendor profile send on email">
      <template
        v-slot:default="{
          link,
          emails,
          attachments,
          attachmentsTitle,
          callback,
        }"
      >
        <SendOnEmail
          :link="link"
          :emails="emails"
          :attachments="attachments"
          attachments-label-key="file_original_client_name"
          :attachmentsTitle="attachmentsTitle"
          :callback="callback"
          :modalsToClose="['vendor-send-on-email']"
        />
      </template>
    </v-modal>

    <v-modal name="vendor-share-contact" title="Vendor share contact">
      <template v-slot:default="{ link, subject, message, callback }">
        <SendOnEmail
          :link="link"
          :subject="subject"
          :message="message"
          :callback="callback"
          :modalsToClose="['vendor-share-contact']"
        />
      </template>
    </v-modal>

    <v-modal name="notes-list">
      <template v-slot:title="{ title }">
        {{ title || 'Notes' }}
      </template>
      <template v-slot:default="{ notes }">
        <NotesList :notes="notes" />
      </template>
    </v-modal>

    <v-modal name="crop-image" title="Crop image">
      <template v-slot:default="{ src, img, callback, aspectRatio, cords }">
        <CropImage
          :src="src"
          :img="img"
          :aspectRatio="aspectRatio"
          :callback="callback"
          :cords="cords"
        />
      </template>
    </v-modal>

    <v-modal name="email">
      <template v-slot:title="{ email }">
        {{ email.type || 'Email' }}
      </template>
      <template v-slot:default="{ email }">
        <Email :email="email" />
      </template>
    </v-modal>

    <v-modal name="send-to-viber" title="Send to viber">
      <template v-slot:default="{ link, message, callback }">
        <SendOnViber
          :link="link"
          :message="message"
          :callback="callback"
          :modalsToClose="['send-to-viber']"
        />
      </template>
    </v-modal>

    <v-modal name="send-to-whatsapp" title="Send to whatsapp">
      <template v-slot:default="{ link, message, callback }">
        <SendOnWhatsapp
          :link="link"
          :message="message"
          :callback="callback"
          :modalsToClose="['send-to-whatsapp']"
        />
      </template>
    </v-modal>

    <v-modal
      name="commodity-full-gallery"
      title="Commodity Full Gallery"
      class="_full-content _wide"
    >
      <template v-slot:title="{ title }">
        <div v-html="title" style="font-size: 1.8rem; line-height: 1.3em"></div>
      </template>
      <template v-slot:default="props">
        <CommodityFullGallery v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="full-gallery" title="Gallery" class="_full-content _wide">
      <template v-slot:title="{ title }">
        <div v-html="title" style="font-size: 1.8rem; line-height: 1.3em"></div>
      </template>
      <template v-slot:default="props">
        <FullGallery v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="commodity-sort-files" title="Commodity sort files">
      <template v-slot:default="props">
        <CommoditySortFiles v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="sort-files" title="Sort files">
      <template v-slot:default="props">
        <SortFiles v-bind="props" />
      </template>
    </v-modal>

    <v-modal
      name="add-commodity-to-loading"
      title="Add commodity to loading"
      class="_full-content _wide"
    >
      <template v-slot:default="{ shippingFileId, containerNumber, callback }">
        <AddCommodityToLoading
          :shippingFileId="shippingFileId"
          :containerNumber="containerNumber"
          :callback="callback"
        />
      </template>
    </v-modal>

    <v-modal
      name="confirm-add-commodity-to-loading"
      title="Add the commodity to loading?"
      class=""
    >
      <template
        v-slot:default="{
          shippingFileId,
          containerNumber,
          commodity,
          callback,
        }"
      >
        <ConfirmAddCommodityToLoading
          :shippingFileId="shippingFileId"
          :containerNumber="containerNumber"
          :callback="callback"
          :commodity="commodity"
        />
      </template>
    </v-modal>

    <v-modal
      name="confirm-container-loading"
      title="Confirm container loading?"
      class=""
    >
      <template
        v-slot:default="{
          shippingFileId,
          containerNumber,
          containerId,
          callback,
        }"
      >
        <ConfirmContainerLoading
          :shippingFileId="shippingFileId"
          :containerNumber="containerNumber"
          :containerId="containerId"
          :callback="callback"
        />
      </template>
    </v-modal>

    <v-modal
      name="cancel-container-loading"
      title="Cancel container loading?"
      class="_warning"
    >
      <template
        v-slot:default="{
          shippingFileId,
          containerNumber,
          containerId,
          callback,
        }"
      >
        <CancelContainerLoading
          :shippingFileId="shippingFileId"
          :containerNumber="containerNumber"
          :containerId="containerId"
          :callback="callback"
        />
      </template>
    </v-modal>

    <v-modal
      name="commodity-show"
      title="Commodity"
      class="_x-wide _full-content"
    >
      <template v-slot:default="{ commodityId, callback }">
        <CommodityShow
          :commodityId="commodityId"
          :callback="callback"
          is-modal
        />
      </template>
    </v-modal>

    <v-modal
      name="file-show"
      title="Shipping file"
      class="_x-wide _full-content"
    >
      <template v-slot:default="{ file, shippingFileId, callback }">
        <ShippingFileShow
          :file="file"
          :shipping-file-id="shippingFileId"
          :callback="callback"
          is-modal
        />
      </template>
    </v-modal>

    <v-modal name="loading-container-notes" class="_full-content">
      <template v-slot:title="{ title }">
        {{ title || 'Notes' }}
      </template>
      <template v-slot:default="{ callback, baseUrl }">
        <LoadingContainerNotes :callback="callback" :baseUrl="baseUrl" />
      </template>
    </v-modal>

    <v-modal name="confirm-input" class="_medium">
      <template v-slot:title="{ title }">
        {{ title || '' }}
      </template>
      <template
        v-slot:default="{ inputLabel, inputValue, callback, inputType }"
      >
        <ModalConfirmInput
          :input-type="inputType"
          :input-label="inputLabel"
          :input-value="inputValue"
          :callback="callback"
        />
      </template>
    </v-modal>

    <v-modal name="code-scanner" title="Code scanner">
      <template v-slot:default="props">
        <CodeScannerCard v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="photo" title="Add Photo">
      <template v-slot:default="props">
        <Photo v-bind="props" />
      </template>
    </v-modal>

    <v-modal name="new-reconcile" title="Reconcile account">
      <template v-slot:default="props">
        <NewReconcile v-bind="props" />
      </template>
    </v-modal>

    <ProjectModals />
  </div>
</template>

<script>
const ModalScanReceipt = () =>
  import(
    /* webpackChunkName: "ModalScanReceipt" */ '@/components/TheModals/ModalScanReceipt'
  )
const CalendarAdd = () =>
  import(/* webpackChunkName: "CalendarAdd" */ '@/components/CalendarAdd.vue')
const ModalQuickAttach = () =>
  import(
    /* webpackChunkName: "ModalQuickAttach" */ '@/components/TheModals/ModalQuickAttach.vue'
  )
import ProjectModals from '@/components/TheModals/ProjectModals'

const SendOnViber = () =>
  import(/* webpackChunkName: "send-on-viber" */ '../SendOnViber')
const SendOnWhatsapp = () =>
  import(/* webpackChunkName: "send-on-whatsapp" */ '../SendOnWhatsapp')
const NotesList = () =>
  import(/* webpackChunkName: "notes-list" */ '../NotesList')
const CropImage = () =>
  import(/* webpackChunkName: "crop-image" */ '../CropImage')
const Email = () => import(/* webpackChunkName: "email" */ '../Email')

const TheConfirm = () =>
  import(/* webpackChunkName: "the-confirm" */ '../TheConfirm')
const MessageModals = () =>
  import(/* webpackChunkName: "message-modals" */ './MessageModals')
const VibersModals = () =>
  import(/* webpackChunkName: "vibers-modals" */ './VibersModals')
const LogsModal = () =>
  import(/* webpackChunkName: "logs-modal" */ './LogsModal')
const SettingsModals = () =>
  import(/* webpackChunkName: "settings-modals" */ './SettingsModals')
const PersonsModals = () =>
  import(/* webpackChunkName: "persons-modals" */ './PersonsModals')
const BookingModals = () =>
  import(/* webpackChunkName: "booking-modals" */ './BookingsModals')
const InvoiceActionsModal = () =>
  import(
    /* webpackChunkName: "invoice-actions-modal" */ './InvoiceActionsModal'
  )
const EstimateActionsModal = () =>
  import(
    /* webpackChunkName: "estimate-actions-modal" */ './EstimateActionsModal'
  )
const PaymentsReceivedModals = () =>
  import(
    /* webpackChunkName: "payments-received-modals" */ './PaymentsReceivedModals'
  )
const DepositActionsModal = () =>
  import(
    /* webpackChunkName: "deposit-actions-modal" */ './DepositActionsModal'
  )
const RatesModals = () =>
  import(/* webpackChunkName: "rates-modals" */ './RatesModals')
const ShippingFileModals = () =>
  import(/* webpackChunkName: "shipping-file-modals" */ './ShippingFileModals')
const CommodityEditModals = () =>
  import(
    /* webpackChunkName: "commodity-edit-modals" */ './CommodityEditModals'
  )
const SubscribeModals = () =>
  import(/* webpackChunkName: "subscribe-modals" */ './SubscribeModals')
const EventEdit = () =>
  import(/* webpackChunkName: "event-edit" */ '../EventEdit')
const InventoryEdit = () =>
  import(/* webpackChunkName: "inventory-edit" */ '../InventoryEdit')
const NewFileTabsAttachDocument = () =>
  import(
    /* webpackChunkName: "new-file-tabs-attach-document" */ '../ShippingFile/Tabs/AttachDocument'
  )
const AESITNEdit = () =>
  import(/* webpackChunkName: "aesitn-edit" */ '../AESITNEdit/index')
const AddNewDocumentType = () =>
  import(
    /* webpackChunkName: "add-new-document-type" */ '../NewShippingFile/AddNewDocumentType'
  )
const PhotoEdit = () =>
  import(/* webpackChunkName: "photo-edit" */ '../PhotoEdit')
const TimeLogEdit = () =>
  import(/* webpackChunkName: "time-log-edit" */ '../TimeLogEdit')
const DepartmentEdit = () =>
  import(/* webpackChunkName: "department-edit" */ '../DepartmentEdit')
const OfficeEdit = () =>
  import(/* webpackChunkName: "office-edit" */ '../OfficeEdit')
const EmployeeTitleEdit = () =>
  import(/* webpackChunkName: "employee-title-edit" */ '../EmployeeTitleEdit')
const Map = () => import(/* webpackChunkName: "map" */ '../Map')
// const CalendarEdit = () =>
//   import(/* webpackChunkName: "calendar-edit" */ '../CalendarEdit')
const CompanyProfileEdit = () =>
  import(/* webpackChunkName: "company-profile-edit" */ '../CompanyProfileEdit')
const qrScanner = () =>
  import(/* webpackChunkName: "qr-scanner" */ '../qrScanner')
const SettingPerson = () =>
  import(/* webpackChunkName: "setting-person" */ '../SettingPerson')
const SendOnEmail = () =>
  import(/* webpackChunkName: "send-on-email" */ '../SendOnEmail')
const CommodityFullGallery = () =>
  import(
    /* webpackChunkName: "commodity-full-gallery" */ '../CommodityShow/CommodityFullGallery'
  )

const FullGallery = () =>
  import(/* webpackChunkName: "full-gallery" */ '@/components/FullGallery')
const CommoditySortFiles = () =>
  import(
    /* webpackChunkName: "commodity-sort-files" */ '../CommodityShow/CommoditySortFiles'
  )
const SortFiles = () =>
  import(/* webpackChunkName: "sort-files" */ './SortFiles')
const AddCommodityToLoading = () =>
  import(
    /* webpackChunkName: "add-commodity-to-loading" */ '../ShippingFile/Tabs/Loading/AddCommodityToLoading'
  )
const ConfirmAddCommodityToLoading = () =>
  import(
    /* webpackChunkName: "confirm-add-commodity-to-loading" */ '../ShippingFile/Tabs/Loading/ConfirmAddCommodityToLoading'
  )
const ConfirmContainerLoading = () =>
  import(
    /* webpackChunkName: "confirm-container-loading" */ '../ShippingFile/Tabs/Loading/ConfirmContainerLoading'
  )
const CancelContainerLoading = () =>
  import(
    /* webpackChunkName: "cancel-container-loading" */ '../ShippingFile/Tabs/Loading/CancelContainerLoading'
  )
const CommodityShow = () =>
  import(
    /* webpackChunkName: "commodity-show" */ '@/components/CommodityShow/CommodityShow'
  )
const TheMobileMenu = () =>
  import(/* webpackChunkName: "the-mobile-menu" */ '@/components/TheMobileMenu')
const LoadingContainerNotes = () =>
  import(
    /* webpackChunkName: "loading-container-notes" */ '@/components/ShippingFile/Tabs/Loading/LoadingContainerNotes'
  )
const ModalConfirmInput = () =>
  import(
    /* webpackChunkName: "modal-confirm-input" */ '@/components/TheModals/ModalConfirmInput'
  )
const CodeScannerCard = () =>
  import(/* webpackChunkName: "code-scanner-card" */ '../CodeScannerCard')
const ShippingFileShow = () =>
  import(
    /* webpackChunkName: "shipping-file-show" */ '@/components/ShippingFileShow/ShippingFileShow'
  )
const ExpenseActionsModal = () =>
  import(
    /* webpackChunkName: "expense-actions-modal" */ '@/components/TheModals/ExpenseActionsModal'
  )
const BillActionsModal = () =>
  import(
    /* webpackChunkName: "bill-actions-modal" */ '@/components/TheModals/BillActionsModal'
  )

const Photo = () => import(/* webpackChunkName: "photo" */ '@/components/Photo')
const NewReconcile = () =>
  import(/* webpackChunkName: "new-reconcile" */ '@/components/NewReconcile')

export default {
  components: {
    ModalQuickAttach,
    CalendarAdd,
    ModalScanReceipt,
    ProjectModals,
    NewReconcile,
    ExpenseActionsModal,
    ShippingFileShow,
    CodeScannerCard,
    ModalConfirmInput,
    LoadingContainerNotes,
    LogsModal,
    TheMobileMenu,
    MessageModals,
    VibersModals,
    TheConfirm,
    BookingModals,
    InvoiceActionsModal,
    EstimateActionsModal,
    PaymentsReceivedModals,
    DepositActionsModal,
    RatesModals,
    CommodityEditModals,
    EventEdit,
    InventoryEdit,
    NewFileTabsAttachDocument,
    AESITNEdit,
    AddNewDocumentType,
    PhotoEdit,
    TimeLogEdit,
    DepartmentEdit,
    OfficeEdit,
    EmployeeTitleEdit,
    Map,
    // CalendarEdit,
    CompanyProfileEdit,
    qrScanner,
    SettingsModals,
    PersonsModals,
    SettingPerson,
    SendOnEmail,
    NotesList,
    CropImage,
    Email,
    SendOnViber,
    SendOnWhatsapp,
    CommodityFullGallery,
    FullGallery,
    CommoditySortFiles,
    SortFiles,
    ShippingFileModals,
    AddCommodityToLoading,
    ConfirmAddCommodityToLoading,
    ConfirmContainerLoading,
    CancelContainerLoading,
    CommodityShow,
    SubscribeModals,
    Photo,
    BillActionsModal,
  },
}
</script>

<style lang="less">
.c-modal-qr-scanner {
  & .modal__content {
    width: auto;
  }

  & .modal__main {
    padding: 1px 0 0;
    border-top: 1px solid @c-gray-lighter;
  }
}
</style>
