<template>
  <div
    :class="selector"
    class="file-show"
    :title="value.file_original_client_name"
  >
    <!--    <span-->
    <!--      v-if="-->
    <!--        (size &&-->
    <!--          sizes.includes(size) &&-->
    <!--          hasThumbnail &&-->
    <!--          [TYPES.VIDEO, TYPES.PDF, TYPES.OTHER].includes(value.type)) ||-->
    <!--        [TYPES.OTHER].includes(value.type)-->
    <!--      "-->
    <!--      :key="imgValue"-->
    <!--      class="file-show__type-ico"-->
    <!--    >-->
    <!--      <BaseSvg v-if="value.type === TYPES.PDF" name="ico-pdf" />-->
    <!--      <span v-else>{{ value.file_extension || value.extension }}</span>-->
    <!--    </span>-->
    <transition name="fade" mode="out-in">
      <img
        v-if="size && sizes.includes(size) && hasThumbnail"
        :key="imgValue"
        v-bind="$attrs"
        :alt="$attrs.alt || value.file_original_client_name"
        v-on="$listeners"
        :src="imgValue"
        loading="lazy"
        class="file-show__file"
      />

      <template
        v-else-if="[TYPES.VIDEO, TYPES.PDF, TYPES.IMAGE].includes(value.type)"
      >
        <!-- VIDEO -->
        <video
          v-if="value.type === TYPES.VIDEO"
          :key="value.path"
          v-bind="$attrs"
          v-on="$listeners"
          class="file-show__file"
        >
          <source :src="value.path" />
          Your browser does not support the video tag.
        </video>

        <!-- PDF -->
        <object
          v-else-if="value.type === TYPES.PDF"
          :key="value.path"
          v-bind="$attrs"
          v-on="$listeners"
          :data="
            value.path +
            (!fullSize
              ? `#toolbar=0&statusbar=0&navpanes=0&scrollbar=0&view=Fit`
              : '')
          "
          :type="value.mime"
          class="file-show__file"
        ></object>
        <!-- IMAGE -->
        <img
          v-else-if="value.type === TYPES.IMAGE"
          :key="imgValue"
          v-bind="$attrs"
          :alt="$attrs.alt || value.file_original_client_name"
          v-on="$listeners"
          :src="imgValue"
          loading="lazy"
          class="file-show__file"
        />
      </template>
      <!-- OTHER -->
      <a
        v-else
        :key="value.path"
        v-bind="$attrs"
        v-on="$listeners"
        target="_blank"
        :href="value.path"
        :download="value.file_original_client_name"
        class="file-show__file _placeholder"
      >
        <img
          v-if="hasThumbnail"
          :key="value['thumbnails_xs']"
          v-bind="$attrs"
          :alt="$attrs.alt || value.file_original_client_name"
          :src="value['thumbnails_xs']"
          loading="lazy"
          class="file-show__file-ico"
        />
        <BaseSvg v-else name="ico-file-solid" />
        <div v-if="showName" class="file-show__name">
          <BaseSvg name="ico-download" />
          {{ value.file_original_client_name }}.{{ value.file_extension }}
        </div>
      </a>
    </transition>
  </div>
</template>

<script>
import { TYPES } from '@/constants/files'
import download from '@/services/download'
import { baseURL } from '@/axios'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      required: true,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: () => 'lg',
    },
    fullSize: Boolean,
    isZoomActive: Boolean,
  },
  data() {
    return {
      TYPES,
      baseURL,
      sizes: ['xs', 'sm', 'md', 'lg'],
      zoomLoaded: false,
    }
  },
  computed: {
    selector() {
      switch (this.value.type) {
        case TYPES.VIDEO:
          return '_video'
        case TYPES.PDF:
          return '_pdf'
        case TYPES.IMAGE:
          return '_image'
        default:
          return '_other'
      }
    },
    imgValue() {
      const pathValue = 'thumbnails_' + this.size
      return (!this.isZoomActive && this.value[pathValue]) || this.value.path
    },
    hasThumbnail() {
      return !!this.value['thumbnails_xs']
    },
  },
  methods: {
    download,
  },
  watch: {
    isZoomActive: {
      handler(to) {
        if (to && this.value?.loaded && !this.zoomLoaded && this.hasThumbnail) {
          this.zoomLoaded = true
          this.$emit('setLoaded', false)
        }
      },
      immediate: true,
    },
  },
}
</script>
